import React from "react";
import { Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from '@mui/material';
import { ChipRibbon } from "../parts/ChipRibbon";
import PeopleIcon from '@mui/icons-material/People';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

// DTO
import { PostsDto } from "../../dto/posts.dto";

interface MovieCardProps {
  item: PostsDto;
}

export const MovieCard: React.FC<MovieCardProps> = ({ item }) => {

  //YoutubeURLからサムネイル画像URLを取得する
  const extractYoutubeThumbnail = (url: String) => {
    let id = '';
    const regex = /(?:\/|v=)([A-Za-z0-9_-]{11})(?:\?|&|$)/;
    const match = url.match(regex);
    if (match) {
      id = match[1];
    }
    return `http://img.youtube.com/vi/${id}/hqdefault.jpg`;
  }
  
  return (
    <>
      <Card
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
        >     
        
        <CardActionArea href={item.url} target="_blank">
          
          <CardMedia
            component="div"
            sx={{
              // 16:9
              pt: "56.25%",
            }}
            image={extractYoutubeThumbnail(item.url)}
          />
          <CardContent sx={{ flexGrow: 1, gap: 100, position:'relative',bg:'#fff' }}>
            <ChipRibbon>{item.type}</ChipRibbon>
            <Typography variant="body2" sx={{ opacity:'0.5' }}>{new Date(item.post_date).toISOString().slice(0, 10)}</Typography>
            <Typography gutterBottom variant="h6" component="h4">
              {item.title}
            </Typography>
            <Typography mb={1}variant="body2">
              <Box sx={{ display:'flex',alignItems: 'center' }}><PeopleIcon sx={{marginRight: 0.5,opacity: 0.5}} fontSize="small"/>{item.kp} / {item.pl.join(', ')}</Box>
            </Typography>
            <Typography>
              <Stack direction="row" spacing={1}>
              {item.genres.map((genre:String) => (
                <Chip label={genre} size="small" />
              ))}
              </Stack>
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </>
  );
};
