import React from "react";

interface Props {
    children: string;
}

export const ChipRibbon = (props: Props) => {

  const { children } = props;

  return (
    <span className="chipRibbon">
        { children }
    </span>
  );
}

