import React from "react";
import { Typography, TextField } from "@mui/material";

interface FreeWordProps {
  title: string;
  id: string;
  margin: "none" | "dense" | "normal" | undefined;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const FreeWord: React.FC<FreeWordProps> = ({ title, id, margin, value, onChange }) => {
  return (
    <>
      <Typography
        variant="subtitle1"
        fontWeight="bold"
        fontSize="1rem"
        color="primary.main"
        mt={2}
      >
        {title}
      </Typography>
      <TextField id={id} margin={margin} fullWidth value={value} onChange={onChange} />
    </>
  );
};
