import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";

// CSS
import styles from "../../css/SignUp.module.css";
import { useEffect } from "react";

function Copyright() {
  return (
    <Box sx={{ p: 6 }} component="footer">
      <Typography
        variant="subtitle1"
        align="center"
        color="text.secondary"
        component="p"
      >
        闇鍋開発局
      </Typography>
    </Box>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export const SignUp: React.FC = () => {
  const [password, setPassword] = React.useState<string>("");
  const [userId, setUserId] = React.useState<string>("");

  // password, userIdをBase64エンコードして返す
  const encodeBase64 = (str: string) => {
    return window.btoa(str);
  };

  useEffect(() => {
    // コンポーネントのマウント時にbodyにクラスを追加
    document.body.classList.add(styles.bodyOverride);

    // コンポーネントのアンマウント時にクラスを削除
    return () => {
      document.body.classList.remove(styles.bodyOverride);
    };
  }, []);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // Base64エンコード
    const encodedPassword = encodeBase64(password);
    const encodedUserId = encodeBase64(userId);

    console.log({
      userId: encodedUserId,
      password: encodedPassword,
    });
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            ユーザー登録
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  autoComplete="userId"
                  name="userId"
                  required
                  fullWidth
                  id="userId"
                  label="User ID"
                  autoFocus
                  value={userId}
                  onChange={(e) => setUserId(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                background:
                  "linear-gradient(to right, #6a11cb 0%, #2575fc 100%)",
              }}
            >
              登録する
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="#" variant="body2">
                  アカウントをお持ちの方はこちら
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright />
      </Container>
    </ThemeProvider>
  );
};
