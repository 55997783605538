import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import chara from "../../img/chara_guuchan.png"
import Typography from "@mui/material/Typography";


interface PasswordModalProps {
  open: boolean;
  onClose: () => void;
  onAuthenticate: () => void;
}

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
  maxWidth: "95vw",
  border: "none",
  backgroundColor: "#fff"
};


export const PasswordModal: React.FC<PasswordModalProps> = ({
  open,
  onClose,
  onAuthenticate,
}) => {
  const [password, setPassword] = useState<string>("");

  const handleAuthenticate = () => {
    if (password === "1995") {
      onAuthenticate();
    } else {
      alert("パスワードが間違っています。");
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="password-modal-title"
      aria-describedby="password-modal-description"
      BackdropProps={{
        style: {
          backgroundColor: 'transparent',
        },
      }}
      sx={{
        '& .MuiBox-root': { // MUI v5の場合
          outline: 'none', // フォーカス時のアウトラインを削除
        },
      }}
    >
      <Box
        sx={{
          ...modalStyle,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img src={chara} alt="ぐーちゃん" className="login-chara" />
        <Typography variant="body1" component="p" sx={{ mb: 2 }} color="textPrimary">
          ようこそ！
        </Typography>
        <TextField
          label="パスワード"
          type="password"
          fullWidth
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          sx={{ backgroundColor: "rgba(255, 255, 255, 0.2)", borderRadius: "10px" }}
        />
        <Button
          onClick={handleAuthenticate}
          variant="contained" 
          sx={{
            fontSize: "1.0rem",
            py: 1.5,
            my: 2,
            borderRadius: "20px",
            width: "50%",
            height: "50px",
          }}
        >
          認証
        </Button>
      </Box>
    </Modal>
  );
};
