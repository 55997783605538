import React from "react";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

interface Props {
    name: string;
    value: string;
    label: string;
    items: Items[];
    onChange: (selected: string) => void; // 選択状態が変更された時に親コンポーネントに通知する
}
interface Items {
    id: string;
    name: string;
}

export const BasicSelect = (props: Props) => {
  const { name, value, label , items, onChange } = props;

  const handleChange = (event: SelectChangeEvent) => {
    onChange(event.target.value as string);
  };

  //要素の最初に空の値を入れる
  const modifiedItems = [{ id: '', name: '' }, ...items];

  return (
    <FormControl fullWidth>
    <Select
        name={name}
        value={value}
        onChange={handleChange}
    >
        {modifiedItems.map((item) => (
            <MenuItem value={item.name}>{item.name}</MenuItem>
        ))}
    </Select>
    </FormControl>
  );
}


