import React from "react";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { Typography, Box } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";

interface SelectDateProps {
  startDate: Dayjs | null;
  endDate: Dayjs | null;
  setStartDate: (date: Dayjs | null) => void;
  setEndDate: (date: Dayjs | null) => void;
  title: string;
}

export const SelectDate: React.FC<SelectDateProps> = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  title,
}) => {
  return (
    <>
      <Typography
        variant="subtitle1"
        fontWeight="bold"
        fontSize="1rem"
        color="primary.main"
        mt={2}
      >
        {title}
      </Typography>
      <Box>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="開始日"
            value={startDate}
            onChange={setStartDate}
            format="YYYY/MM/DD"
          />
        </LocalizationProvider>
      </Box>
      <Box mt={1}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="終了日"
            value={endDate}
            onChange={setEndDate}
            format="YYYY/MM/DD"
          />
        </LocalizationProvider>
      </Box>
    </>
  );
};
