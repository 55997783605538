import React from "react";
import { Typography, FormGroup, Stack, FormControlLabel, Checkbox } from "@mui/material";

// DTO
import { SearchItemsDTO } from "../../dto/searchItem.dto";

interface CheckboxListProps {
  items: SearchItemsDTO[];
  title: string;
  value: string[]; // 選択されている項目のIDの配列
  className?: string;
  onChange: (selected: string[]) => void; // 選択状態が変更された時に親コンポーネントに通知する
}

export const CheckboxList: React.FC<CheckboxListProps> = ({ items, title, value, className, onChange }) => {
  const handleToggle = (id: string) => {
    const currentIndex = value.indexOf(id);
    const newValue = [...value];

    if (currentIndex === -1) {
      newValue.push(id); // 選択されていない項目は追加
    } else {
      newValue.splice(currentIndex, 1); // 既に選択されている項目は削除
    }

    onChange(newValue); // 新しい選択状態を親コンポーネントに通知
  };

  return (
    <>
      <Typography variant="subtitle1" fontWeight="bold" fontSize="1rem" color="primary.main" mt={2}>
        {title}
      </Typography>
      <FormGroup className={className}>
        <Stack direction="row" flexWrap="wrap">
          {items.length > 0 ? (
            items.map((item) => (
              <FormControlLabel
                key={item.id}
                value={item.id}
                control={<Checkbox checked={value.includes(item.name)} onChange={() => handleToggle(item.name)} />}
                label={item.name}
              />
            ))
          ) : (
            <div>loading...</div>
          )}
        </Stack>
      </FormGroup>
    </>
  );
};
