export const sanc = () => {
    const sancList = [
        '0/1d3',
        '0/1d3',
        '0/1d3',
        '0/1d3',
        '1/1d6',
        '1/1d6',
        '1/1d6',
        '1/1d6',
        '1d3/1d10',
        '1d3/1d10',
        '1d6/1d20',
        '1d6/1d20',
        '1d20/1d100',
    ]
    return sancList[Math.floor(Math.random() * sancList.length)];
}