import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { Dayjs } from "dayjs";

// DTO
import { SearchItemDTO } from "../../dto/searchItem.dto";
import { SearchConditionsDTO } from "../../dto/searchconditions.dto";

// components
import { CheckboxList } from "../molecules/CheckboxList";
import { BaseSelect } from "../molecules/BaseSelect";
import { SelectDate } from "../molecules/SelectDate";
import { FreeWord } from "../molecules/FreeWord";
import IconButton from '@mui/material/IconButton';

//icons
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';

const styleModal = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "min(380px,100%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: '32px 32px 16px',
  maxHeight: "90vh",
  overflowY: "auto",
  borderRadius: "8px"
};

interface SearchAreaProps {
  open: boolean;
  handleClose: () => void;
  getVideoList: () => void;
  SearchItem: SearchItemDTO;
  searchConditions: SearchConditionsDTO;
  searchConditionChange: (conditionType: string, value: any) => void;
  resetSearchConditions: () => void;
}

export const SearchArea: React.FC<SearchAreaProps> = ({
  open,
  handleClose,
  getVideoList,
  SearchItem,
  searchConditions,
  searchConditionChange,
  resetSearchConditions
}) => {


    //titleが変更された時
    const handleTitleChange = (selectedTitles: string) => {
      searchConditionChange('title',selectedTitles);
    };
    //start_dateが変更された時
    const handleStartChange = (selectedStart: Dayjs | null) => {
      searchConditionChange('start_date',selectedStart);
    };
    //end_dateが変更された時
    const handleEndChange = (selectedEnd: Dayjs | null) => {
      searchConditionChange('end_date',selectedEnd);
    };
    //kpが変更された時
    const handleKpChange = (selectedKp: string) => {
      searchConditionChange('kp',selectedKp);
    };
    //peopleが変更された時
    const handlePeopleChange = (selectedPeople: string) => {
      searchConditionChange('people',selectedPeople);
    };
    //Playerが変更された時
    const handlePlayerChange = (selectedPlayers: string[]) => {
      searchConditionChange('pl', selectedPlayers);
    };
    // Typeが変更された時
    const handleTypeChange = (selectedTypes: string) => {
      searchConditionChange('type', selectedTypes);
    };
    // Genreが変更された時
    const handleGenreChange = (selectedGenres: string[]) => {
      searchConditionChange('genres', selectedGenres);
    };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal}>
          <IconButton aria-label="clouse" color="primary" sx={{position:'absolute',top:10,right:10}} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
          <Button startIcon={<DeleteIcon />} sx={{pl:0,pr:0}} onClick={resetSearchConditions}>
            条件をリセットする
          </Button>
          <FreeWord
            title="フリーワード"
            id="title"
            margin="none"
            value={searchConditions.title}
            onChange={(e) => handleTitleChange(e.target.value)}
          />

          <SelectDate
            startDate={null}
            endDate={null}
            setStartDate={(date) => handleStartChange(date)}
            setEndDate={(date) => handleEndChange(date)}
            title="日付"
          />

          <BaseSelect
            items={SearchItem?.type || []}
            value={searchConditions.type}
            title="カテゴリー"
            name="カテゴリー"
            label="カテゴリー"
            onChange={handleTypeChange}
          />

          <BaseSelect
            items={SearchItem?.keeper || []}
            value={searchConditions.kp}
            title="KP"
            name="KP"
            label="KP"
            onChange={handleKpChange}
          />

          <CheckboxList
            items={SearchItem?.player || []}
            title="PL"
            value={searchConditions.pl}
            onChange={handlePlayerChange}
          />

          <BaseSelect
            items={SearchItem?.player_count || []}
            value={searchConditions.people}
            title="プレイヤー数"
            name="player_count"
            label="プレイヤー数"
            onChange={handlePeopleChange}
          />

          <CheckboxList
            items={SearchItem?.genre || []}
            title="ジャンル"
            value={searchConditions.genres}
            onChange={handleGenreChange}
            className="mb16"
          />

          <Button variant="contained" fullWidth onClick={getVideoList} sx={{position:'sticky', bottom:'0', zIndex:"1",height:"50px",fontSize:"1.2rem"}}>
            SEARCH
          </Button>
        </Box>
      </Modal>
    </div>
  );
};
