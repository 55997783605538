import * as React from "react";
import { useState } from "react";
import { useEffect } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { SearchItemDTO } from "../../dto/searchItem.dto";
import SearchIcon from "@mui/icons-material/Search";
import CircularProgress from "@mui/material/CircularProgress";

// API_URL
import { API_URL } from "../../context/api_url";

// copornents
import { SearchArea } from "./SearchArea";
import { MovieCard } from "../molecules/MovieCard";
import { PasswordModal } from './PasswordModal';

// dto
import { SearchConditionsDTO } from "../../dto/searchconditions.dto";
import { PostsDto } from "../../dto/posts.dto";

// oasobi
import {sanc} from "../../oasobi/sanc";
import devteamLogo from "../../img/logo_devteam.png";

export const Main: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [posts, setPosts] = useState<PostsDto[]>([]); // 動画データ

  const [loading, setLoading] = useState(false); // ローディング状態

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  console.log("isAuthenticated", isAuthenticated);
  
  //検索クエリ
  const defaultConditions = {
    title: "",
    start_date: "",
    end_date: "",
    kp: "",
    pl: [],
    type: "",
    genres: [],
    people: "0"
  }

  // 検索クエリのState
  const [searchConditions, setSearchConditions] = useState<SearchConditionsDTO>(defaultConditions);
  console.log(searchConditions);

  // 検索クエリの更新
  const searchConditionChange = (conditionType: string, value: any) => {
    setSearchConditions((prevConditions: SearchConditionsDTO) => ({
      ...prevConditions,
      [conditionType]: value,
    }));
  }
  // 検索クエリのリセット
  const resetSearchCondition = () => {
    setSearchConditions(defaultConditions);
  }

  // 検索を開く
  const handleOpen = () => {
    setOpen(true);
  };
  // 検索を閉じる
  const handleClose = () => {
    setOpen(false);
  }

  // 検索結果の読み込み
  const getVideoList = () => {
    setOpen(false);

    // get_video_listへのリクエスト
    const getVideoList = async () => {
      setLoading(true); // API呼び出し前にローディング状態をtrueに
      const response = await fetch(API_URL.get_video_list, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          title: searchConditions.title,
          start_date: searchConditions.start_date,
          end_date: searchConditions.end_date,
          kp: searchConditions.kp,
          pl: searchConditions.pl,
          type: searchConditions.type,
          genres: searchConditions.genres,
          people: Number(searchConditions.people),
        }),
      });

      if (response.ok) {
        const data = await response.json();
        // console.log(data);
        setPosts(data.body);
        setLoading(false); // ローディング状態をfalseに
      } else {
        setLoading(false); // ローディング状態をfalseに
        console.error("HTTP error:", response.status);
      }
    };
    getVideoList();
  };

  //検索項目
  const [searchItems, setSearchItems] = React.useState<SearchItemDTO>({
    type: [],
    genre: [],
    player: [],
    keeper: [],
    player_count: [],
  });

  // get_video_search_listへのリクエスト
  useEffect(() => {
    const getVideoSearchList = async () => {
      const response = await fetch(API_URL.get_video_search_list, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        const data = await response.json();
        console.log(data.result);
        setSearchItems(data.result);
      } else {
        console.error("HTTP error:", response.status);
      }
    };
    // get_video_listへのリクエスト
    const getVideoList = async () => {
      setLoading(true); // API呼び出し前にローディング状態をtrueに
      const response = await fetch(API_URL.get_video_list, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          title: searchConditions.title,
          start_date: searchConditions.start_date,
          end_date: searchConditions.end_date,
          kp: searchConditions.kp,
          pl: searchConditions.pl,
          type: searchConditions.type,
          genres: searchConditions.genres,
          people: Number(searchConditions.people),
        }),
      });

      if (response.ok) {
        const data = await response.json();
        // 上位10件のみ表示
        setPosts(data.body.slice(0, 15));
        setLoading(false); // ローディング状態をfalseに
      } else {
        setLoading(false); // ローディング状態をfalseに
        console.error("HTTP error:", response.status);
      }
    };
    getVideoList();
    getVideoSearchList();
  }, []);

  return (
    <div>
      <div className="main-bg"></div>
    {!isAuthenticated ? (
      <PasswordModal
        open={!isAuthenticated}
        onAuthenticate={() => setIsAuthenticated(true)}
        onClose={() => {}}
      />
    ) : (
      <Box>
        <CssBaseline />
        <SearchArea
          open={open}
          handleClose={handleClose}
          getVideoList={getVideoList}
          SearchItem={searchItems}
          searchConditions={searchConditions}
          searchConditionChange={searchConditionChange}
          resetSearchConditions={resetSearchCondition}
        />
        <main>
          <Container sx={{ pt: 14, pb: 8 }} maxWidth="md">
            <Button
              onClick={handleOpen}
              variant="contained"
              sx={{
                width: "100%",
                mb: "32px",
                justifyContent: "flex-start",
              }}
            >
              <SearchIcon fontSize="large" sx={{ mr: "8px" }} />
              <Typography>検索する</Typography>
            </Button>
            {/* ローディング表示 */}
            {loading ? (
              <Box sx={{ display: "flex", justifyContent: "center", p: 3 }}>
                <CircularProgress />
              </Box>
            ) : (
              // 動画データを表示
              posts.length > 0 ? (
                <Grid container spacing={4}>
                {posts.map((post) => (
                    <Grid item xs={12} sm={6} md={4} key={post.url}>
                      <MovieCard item={post} />
                    </Grid>
                ))}
                </Grid> 
              ) : (
                <Typography sx={{ textAlign: "center", ml: "auto", mr: "auto" }}>
                  お探しの動画は見つかりませんでした<br />
                  条件を変えて検索しなければいけないと<br />
                  気付いてしまった貴方は <strong>SANc {sanc()}</strong> です
                </Typography>
              )
            )}
          </Container>
        </main>
        {/* Footer */}
        <Box sx={{ p: 6 }} component="footer">
        <img src={devteamLogo} className="footer-logo" alt="Dev Team Logo" />
        </Box>
        {/* End footer */}
      </Box>
    )}
    </div>
  );
};
