import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#3b8dd4',
    },
    secondary: {
      main: '#dc004e',
    },
    text: { primary: '#3b3c3f' },
  },
  typography: {
    fontFamily: '"Nunito Sans", "Zen Kaku Gothic New", sans-serif',
  },
  // 他のカスタマイズもここに追加できます
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          // すべてのボタンのデフォルトスタイル
          textTransform: 'none', // テキストの大文字変換を無効化
          borderRadius: 8, // ボタンの角を丸くする
        },
        containedPrimary: {
          // プライマリカラーのボタンのスタイル
          background: 'linear-gradient(90deg, #30a2ff 10%, #736EFE 100%);',
          color: '#fff',
        },
        containedSecondary: {
          // セカンダリカラーのボタンのスタイル
          backgroundColor: '#dc004e',
          color: '#fff',
          '&:hover': {
            backgroundColor: '#9a0036',
          },
        },
      },
    },
  },
});

export default theme;